import React, { useState, useEffect } from 'react';
import FirmTable from './firmsTable';
import {
    Box, Container, Typography, Grid, TextField, useMediaQuery,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../styles/StyleTheme';
import buildingsBackground from '../../assets/images/fondo-edificiospng.png';
import Loading from '../../components/Loading';
import Pagination from '@mui/material/Pagination';
import { publicAxios } from '../../apiClients';
import SelectOptionMM from '../../components/SelectOptionMM';
import MMLogo from '../../assets/banners/Recurso-6M-m.png';
import Button from '@mui/material/Button';


const MarketMonitor = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [firms, setFirms] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [sortBy, setSortBy] = useState({ field: 'position', direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const firmsPerPage = 30;
    const isMobile = useMediaQuery('(max-width: 768px)');
    const isXLScreen = useMediaQuery('(min-width:1550px)');
    const isXXLScreen = useMediaQuery('(min-width:2000px)');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await publicAxios.get('/institution/market-monitor/table');
                const data = response.data;
                setFirms(data);
            } catch (error) {
                console.error('Error fetching firms:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();

        // Recuperar los filtros guardados
        const savedFilters = JSON.parse(localStorage.getItem('marketMonitorFilters'));
        if (savedFilters) {
            setSearchTerm(savedFilters.searchTerm || '');
            setSelectedCountry(savedFilters.selectedCountry || '');
            setSelectedRegion(savedFilters.selectedRegion || '');
            setCurrentPage(savedFilters.currentPage || 1);
        }
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        saveFilters(event.target.value, selectedCountry, selectedRegion, currentPage);
    };

    const handleCountryChange = (newCountryId) => {
        setSelectedCountry(newCountryId || '');
        setSelectedRegion(''); // Limpiar la región cuando se selecciona un país
        setCurrentPage(1); // Reiniciar a la primera página al aplicar filtro
        saveFilters(searchTerm, newCountryId, '', 1);
    };

    const handleRegionChange = (newRegionId) => {
        setSelectedRegion(newRegionId || '');
        setSelectedCountry('');
        setCurrentPage(1);
        saveFilters(searchTerm, '', newRegionId, 1);
    };

    const saveFilters = (search, country, region, page) => {
        localStorage.setItem('marketMonitorFilters', JSON.stringify({
            searchTerm: search,
            selectedCountry: country,
            selectedRegion: region,
            currentPage: page
        }));
    }

    const sortFirms = (firms) => {
        return [...firms].sort((a, b) => {
            if (sortBy.field === 'position') {
                const positionDiff = a.position - b.position;
                if (positionDiff === 0) {
                    return sortBy.direction === 'asc'
                        ? a.institutionName.localeCompare(b.institutionName)
                        : b.institutionName.localeCompare(a.institutionName);
                }
                return sortBy.direction === 'asc' ? positionDiff : -positionDiff;
            }

            if (sortBy.field === 'totalScore') {
                const scoreDiff = a.totalScore - b.totalScore;
                if (scoreDiff === 0) {
                    return sortBy.direction === 'desc'
                        ? a.institutionName.localeCompare(b.institutionName)
                        : b.institutionName.localeCompare(a.institutionName);
                }
                return sortBy.direction === 'asc' ? scoreDiff : -scoreDiff;
            }

            if (sortBy.field === 'institutionName') {
                return sortBy.direction === 'asc'
                    ? a.institutionName.localeCompare(b.institutionName)
                    : b.institutionName.localeCompare(a.institutionName);
            }

            if (sortBy.field === 'region') {
                return sortBy.direction === 'asc'
                    ? a.country.region.localeCompare(b.country.region)
                    : b.country.region.localeCompare(a.country.region);
            }

            if (sortBy.field === 'country') {
                return sortBy.direction === 'asc'
                    ? a.country.name.localeCompare(b.country.name)
                    : b.country.name.localeCompare(a.country.name);
            }

            return 0;
        });
    }

    const uniqueRegions = [...new Set(firms
        .filter((firm) => firm.country && firm.country.region)
        .map((firm) => firm.country.region))]
        .sort((a, b) => a.localeCompare(b))
        .map((region) => ({ id: region, name: region }));

    const uniqueCountries = selectedRegion
        ? [...new Set(firms
            .filter((firm) => firm.country && firm.country.region === selectedRegion)
            .map((firm) => firm.country.name))]
            .sort((a, b) => a.localeCompare(b))
            .map((country) => ({ id: country, name: country }))
        : [...new Set(firms
            .filter((firm) => firm.country && firm.country.name)
            .map((firm) => firm.country.name))]
            .sort((a, b) => a.localeCompare(b))
            .map((country) => ({ id: country, name: country }));

    const filteredFirms = firms.filter(
        (firm) =>
            firm.institutionName.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (!selectedCountry || firm.country.name === selectedCountry) &&
            (!selectedRegion || firm.country.region === selectedRegion)
    );

    const sortedFirms = sortFirms(filteredFirms);
    const indexOfLastFirm = currentPage * firmsPerPage;
    const indexOfFirstFirm = indexOfLastFirm - firmsPerPage;
    const currentFirms = sortedFirms.slice(indexOfFirstFirm, indexOfLastFirm);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        saveFilters(searchTerm, selectedCountry, selectedRegion, value);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    backgroundImage: `url(${buildingsBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    p: { xs: 3, md: 6 },
                    textAlign: 'start',
                }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        paddingLeft: isXXLScreen ? '430px' : isXLScreen ? '300px' : { xs: '15px', md: '160px' },
                        paddingRight: isXXLScreen ? '430px' : isXLScreen ? '300px' : { xs: '15px', md: '160px' },
                    }}
                >
                    <Box
                        component="img"
                        src={MMLogo}
                        alt="Market Monitor"
                        sx={{
                            width: isMobile ? '60%' : '45%',
                            height: isMobile ? '60%' : '45%',
                        }}
                    />
                    <Typography
                        variant="h6"
                        sx={{
                            display: 'start',
                            ml: '0.5%',
                            color: 'white',
                            mt: '-5%',
                            paddingRight: { xs: '5%', md: '21%', xl: '40%' },
                            lineHeight: 1.2,
                            fontSize: isMobile ? '1rem' : '1.3rem',
                        }}
                    >
                        Monitor the real-time{' '}
                        <span style={{ fontSize: '110%' }}>positioning</span> of firms in
                        your country and compare it with their global{' '}
                        <span style={{ fontSize: '110%' }}>competition</span>
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            ml: '0.5%',
                            mt: '3%',
                            color: 'white',
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography sx={{ fontSize: '80%' }}>
                                Our AI model is fed with information coming from public
                                sources or provided by the firms (verified by our team). Do
                                you want to register a new firm or update displayed
                                information?
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                ml: { xs: 0, md: 2 },
                                mt: { xs: 2, md: 0 },
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            <Button
                                component="a"
                                href="https://thelegalindustry.com/contact-us/"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#f16548',
                                    color: '#fff',
                                    borderRadius: 4,
                                    height: 30,
                                    textTransform: 'none',
                                    whiteSpace: 'nowrap',
                                    '&:hover': { backgroundColor: '#e15538' },
                                }}
                            >
                                Contact us
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Box>
            <Box
                sx={{
                    backgroundColor: '#03263D',
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'white',
                    py: 4,
                    paddingLeft: isXXLScreen ? '500px' : isXLScreen ? '350px' : { xs: '20px', md: '200px' },
                    paddingRight: isXXLScreen ? '500px' : isXLScreen ? '350px' : { xs: '20px', md: '200px' },
                    paddingBottom: '75px',
                    overflow: 'hidden',
                }}
            >
                <Container sx={{ mt: '30px', mb: 4 }}>
                    <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        sx={{ justifyContent: 'center', px: { xs: 0, md: 5 } }}
                    >
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Search firm..."
                                variant="outlined"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                sx={{
                                    width: '100%',
                                    padding: '8px 0',
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: '#03263D',
                                        height: '33px',
                                        '& fieldset': {
                                            borderColor: 'white',
                                            borderRadius: '18px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                            borderRadius: '18px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#F67147',
                                            borderRadius: '18px',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white',
                                        fontSize: '0.8rem',
                                    },
                                    '& .MuiInputBase-input': {
                                        color: 'white',
                                    },
                                }}
                                InputLabelProps={{ style: { color: 'white' } }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <SelectOptionMM
                                options={uniqueRegions.length > 0 ? uniqueRegions : [{ id: '', name: 'No options' }]}
                                onSelectionChange={handleRegionChange}
                                name="Filter by Region"
                                width="100%"
                                selectedOption={selectedRegion}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <SelectOptionMM
                                options={uniqueCountries.length > 0 ? uniqueCountries : [{ id: '', name: 'No options' }]}
                                onSelectionChange={handleCountryChange}
                                name="Filter by Country"
                                width="100%"
                                selectedOption={selectedCountry}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container
                    sx={{
                        flexGrow: 1,
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                    }}
                >
                    <FirmTable
                        firms={currentFirms}
                        sortBy={sortBy}
                        setSortBy={setSortBy} // Pasar el estado de orden a FirmTable
                    />
                    <Pagination
                        count={Math.ceil(filteredFirms.length / firmsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{
                            mt: 2,
                            justifyContent: 'center',
                            display: 'flex',
                            '& .MuiPaginationItem-root': {
                                color: 'white',
                            },
                        }}
                    />
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default MarketMonitor;
