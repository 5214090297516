import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';
import image from '../assets/logo/cropped-LIR.png'; // Logo principal
import logoMM from '../assets/banners/Recurso-6M-m-v2.png'; // Logo Market Monitor

// Define las animaciones
const slide = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// Componente funcional que devuelve el Loader centrado
const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed', // Posicionar relativo a la ventana
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.8)', // Fondo blanco con opacidad profesional
        zIndex: 1500, // Por encima de otros elementos
        flexDirection: 'column', // Coloca los elementos verticalmente
      }}
    >
      {/* Logo principal */}
      <Box
        component="img"
        src={image}
        alt="Institution Logo"
        sx={{
          height: '6rem',
          width: '6rem',
          display: 'inline-block',
          marginBottom: '1rem', // Espacio entre el logo principal y el secundario
        }}
      />
      {/* Logo Market Monitor */}
      <Box
        component="img"
        src={logoMM}
        alt="Market Monitor Logo"
        sx={{
          height: '2rem',
          width: '6rem',
          animation: `${pulse} 1.5s ease-in-out infinite`, // Animación de pulso para dar énfasis
          marginBottom: '0.5rem', // Espacio entre el logo Market Monitor y la barra
        }}
      />
      {/* Barra de carga */}
      <Box
        sx={{
          position: 'relative',
          width: '12rem',
          height: '0.5rem',
          background: '#e0e0e0', // Barra base
          overflow: 'hidden',
          borderRadius: '0.25rem', // Bordes redondeados
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '50%',
            height: '100%',
            background: 'rgb(229, 60, 97)', // Color del barrido
            animation: `${slide} 1.5s linear infinite`, // Movimiento continuo
          }}
        />
      </Box>
    </Box>
  );
};

export default Loading;
